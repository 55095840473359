import React from 'react';
import { graphql } from 'gatsby';
import PageLayout from '../components/PageLayout';
import MetaTags from '../components/MetaTags';
import Banner from '../components/Banner';
import ContentArea from '../components/ContentArea';
import { selectPageContent } from '../selectors/selectPageContent';

export const query = graphql`
  query($path: String!) {
    markdownRemark(fields: { slug: { eq: $path } }) {
      ...PAGE_CONTENT_FRAGMENT
    }
  }
`;

const PageTemplate = ({ data, location: { href } }) => {
  const { title, excerpt, html } = selectPageContent(data);

  return (
    <PageLayout>
      <MetaTags url={href} metaTitle={title} metaDescription={excerpt} />
      <Banner title={title} excerpt={excerpt} />
      <ContentArea dangerouslySetInnerHTML={{ __html: html }} />
    </PageLayout>
  );
};

export default PageTemplate;
